import React from 'react';

interface LoadingProps {
  size: number;
}

export const Loading: React.FC<LoadingProps> = ({ size }) => {
  return (
    <div className="flex-center  h-10 w-[50%]">
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className="animate-spin"
      >
        <div
          className="h-full w-full rounded-[50%] border-4
       border-b-primary border-t-primary"
        ></div>
      </div>
    </div>
  );
};
