import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Appointment from './reservation/components/Appointment';
import InstructionsModal from './reservation/components/InstructionsModal';
import RemovalModal from './reservation/components/RemovalModal';
import AddAttachmentModal from './reservation/components/AddAttachmentModal';
import useStore from 'store';
import { Loading } from 'components/Loader';

import { AppointmentType, PolicyType, StatusTypeEnum } from 'types';

import { ArrowBackIcon, AdditionIcon } from 'components/svgs';
import myAppointmentsCalendar from 'img/myAppointmentsCalendar.png';
import { useQuery } from '@tanstack/react-query';
import request from 'request';
import ErrorMessage from './reservation/components/ErrorMessage';
import { useArrivalPolicies } from 'api';
import useStoreServices from 'storeServices';
import { isEmpty } from 'lodash';

export default function Appointments() {
  const [unavailableService, setUnavailableService] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const fetchAppointments = async () => {
    const { data } = await request.get('/api/v1/reservations/');
    return data
      .filter(({ start, status }: AppointmentType) => {
        const now = new Date();
        return (
          start &&
          new Date(start) > now &&
          status &&
          [StatusTypeEnum.BOOKED, StatusTypeEnum.REQUESTED].includes(
            status.status_type,
          )
        );
      })
      .sort(
        (a: AppointmentType, b: AppointmentType) =>
          new Date(a.start!).getTime() - new Date(b.start!).getTime(),
      );
  };

  const {
    data,
    isFetching,
    refetch: refetchAppointments,
  } = useQuery<any, Error>({
    queryKey: ['userAppointmentsData'],
    staleTime: 0,
    queryFn: fetchAppointments,
    onError: (error: any) => {
      setUnavailableService(true);
      console.log(error);
    },
  });

  const {
    deleteAppointment,
    setRemovalModalState,
    setAddAttachmentModalState,
    isInstructionsModalOpen,
    setIsInstuctionsModalOpen,
    removalModalState,
    addAttachmentModalState,
  } = useStore();

  const { availableArrivalPolicies, setAvailableArrivalPolicies } =
    useStoreServices();

  const { data: policiesData, isFetching: policiesIsFetching } =
    useArrivalPolicies();

  const handleDeleteAppointment = async (
    appointment: AppointmentType,
  ): Promise<void> => {
    try {
      await request.put(`/api/v1/reservations/${appointment.id}/`, {
        status_id: 4,
      });

      deleteAppointment(appointment.id || '');
      refetchAppointments();
    } catch (error: any) {
      console.log(error);
      if (error.response.status >= 500) {
        setErrorMessage(
          'Υπάρχει ένα τεχνικό πρόβλημα αυτήν την στιγμή. Το ραντεβού σας δεν έχει διαγραφεί.',
        );
      }
    }
  };

  const getAppointmentAttachments = async (
    appointment: AppointmentType,
  ): Promise<void> => {
    try {
      const { data } = await request.get(
        `/api/v1/reservations/${appointment.id}/attachments/`,
      );
      appointment.attachments = [...data];
    } catch (error: any) {
      // todo: error handling
      // setErrorMsg('Ούπς! Κατι πηγε στραβά! Παρακαλώ προσπαθήστε αργοτερα!');
      console.log(error);
    }
  };

  useEffect(() => {
    !policiesIsFetching &&
      isEmpty(availableArrivalPolicies) &&
      setAvailableArrivalPolicies(policiesData as PolicyType[]);
  });

  return (
    <main className="mx-auto w-full max-w-screen-2xl px-4 py-7 md:py-8 lg:px-10">
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mb-10 flex flex-col items-center gap-6 rounded-3xl  px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <div className="my-10 flex w-full items-center justify-start">
          <button
            onClick={() => {
              navigate('/account');
            }}
            className="h-10 w-10 cursor-pointer self-start text-blue-600 hover:text-gray-600"
          >
            <ArrowBackIcon />
          </button>
          <div className="w-full text-xl font-bold md:text-3xl lg:text-4xl">
            <h1>Τα ραντεβού μου</h1>
          </div>
        </div>
        {errorMessage && <ErrorMessage message={errorMessage} />}
        {isFetching ? (
          <div className="justify-center text-center">
            <Loading size={120} />
          </div>
        ) : data?.length > 0 ? (
          <div data-test="appointments-list">
            {data.map((appointment: AppointmentType) => (
              <Appointment
                key={crypto.randomUUID()}
                appointment={appointment}
                policies={availableArrivalPolicies as PolicyType[]}
                onDelete={() => {
                  setRemovalModalState({ isOpen: true, appointment });
                }}
                onAddAttachment={() => {
                  getAppointmentAttachments(appointment);
                  setAddAttachmentModalState({ isOpen: true, appointment });
                }}
              />
            ))}
          </div>
        ) : (
          <div>
            <img src={myAppointmentsCalendar} alt="myAppointmentsCalendar" />
            <p className="mb-6 mt-10">
              {unavailableService
                ? 'Υπάρχει ένα τεχνικό πρόβλημα αυτήν την στιγμή. Παρακαλούμε προσπαθήστε ξανά αργότερα.'
                : 'Αυτή τη στιγμή δεν υπάρχουν προγραμματισμένα ραντεβού.'}
            </p>
            <button
              type="button"
              onClick={() => {
                navigate('/new/reservations');
              }}
              className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-6 py-4 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              <span>Νέο ραντεβού</span>
              <AdditionIcon className="ml-2.5 h-6 w-6" />
            </button>
          </div>
        )}

        <InstructionsModal
          open={isInstructionsModalOpen}
          onClose={() => {
            setIsInstuctionsModalOpen(false);
          }}
        />

        <RemovalModal
          open={removalModalState.isOpen}
          onClose={() => {
            setRemovalModalState({ isOpen: false, appointment: undefined });
          }}
          onAppDelete={() => {
            handleDeleteAppointment(removalModalState.appointment!);
          }}
          service={removalModalState?.appointment?.service!}
          date={removalModalState?.appointment?.start!}
          time={removalModalState?.appointment?.time!}
        />

        <AddAttachmentModal
          open={addAttachmentModalState.isOpen}
          onClose={() => {
            setAddAttachmentModalState({
              isOpen: false,
              appointment: undefined,
            });
          }}
          appointment={addAttachmentModalState?.appointment!}
        />
      </div>
    </main>
  );
}
