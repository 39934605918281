import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { InsuranceProvidersType, PolicyType } from 'types';

export interface State {
  availableInsuranceProviders: InsuranceProvidersType[];
  setAvailableInsuranceProviders: (
    insurances: InsuranceProvidersType[],
  ) => void;
  availableArrivalPolicies: PolicyType[];
  setAvailableArrivalPolicies: (policies: PolicyType[]) => void;
}

const useStoreServices = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        availableInsuranceProviders: [],
        setAvailableInsuranceProviders: (insurances) =>
          set({ availableInsuranceProviders: [...insurances] }),
        availableArrivalPolicies: [],
        setAvailableArrivalPolicies: (policies) =>
          set({ availableArrivalPolicies: [...policies] }),
      }),
      {
        name: 'services',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export default useStoreServices;
