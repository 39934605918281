import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider, ProtectedLayout, AuthLayout } from 'auth/AuthProvider';
import Account from 'views/Account';
import Announcements from 'views/Announcements';
import App from 'views/App';
import Appointments from 'views/Appointments';
import Callback from 'views/Callback';
import ConfirmationMsg from 'views/ConfirmationMsg';
import Contact from 'views/Contact';
import Details from 'views/Details';
import Error404 from 'views/Error404';
import Error500 from 'views/Error500';
import Guide from 'views/Guide';
import Home from 'views/Home';
import Login from 'views/Login';
import Maintenance from 'views/Maintenance';
import New from 'views/reservation/New';
import NewPassword from 'views/NewPassword';
import Recover from 'views/Recover';
import Register from 'views/Register';
import Tools from 'views/Tools';
import Reservations from 'views/reservation/Steps/Reservations';
import UserInput from 'views/reservation/Steps/UserInput';
import ReservationsPreview from 'views/reservation/Steps/ReservationsPreview';
import ReservationsConfirm from 'views/reservation/Steps/ReservationsConfirm';
import UserEmailConfirmation from 'views/UserEmailConfirmation';
import ContactEmailConfirmation from 'views/ContactEmailConfirmation';
import CancelReservation from 'views/CancelReservation';
import Faq from 'views/FAQ';

const Router = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route element={<AuthLayout />}>
          <Route path="/login/" element={<Login />} />
        </Route>
        <Route
          path="/verify-contact-email/"
          element={<ContactEmailConfirmation />}
        />
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="/announcements/" element={<Announcements />} />
            <Route path="/contact/" element={<Contact />} />
            <Route path="/callback/" element={<Callback />} />
            <Route path="/error500/" element={<Error500 />} />
            <Route path="/verify-email/" element={<UserEmailConfirmation />} />
            <Route
              path="/cancel-reservation/"
              element={<CancelReservation />}
            />
            <Route path="/faq/" element={<Faq />} />
            <Route path="/maintenance/" element={<Maintenance />} />

            <Route path="new" element={<New />}>
              <Route path="reservations" element={<Reservations />} />
              <Route path="user-input" element={<UserInput />} />
              <Route
                path="reservations-preview"
                element={<ReservationsPreview />}
              />
              <Route
                path="reservations-confirm"
                element={<ReservationsConfirm />}
              />
              <Route path="details" element={<Details />} />
            </Route>

            <Route path="/newpassword/" element={<NewPassword />} />
            <Route path="/recover/" element={<Recover />} />
            <Route path="/register/" element={<Register />} />
            <Route path="/tools/callback/" element={<Callback />} />
            <Route path="/confirmationmsg/" element={<ConfirmationMsg />} />
            <Route path="/tools/guide/" element={<Guide />} />
            <Route path="/tools/" element={<Tools />} />

            <Route path="/account/" element={<Account />} />
            <Route path="/appointments/" element={<Appointments />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

export default Router;
