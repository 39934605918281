import { TickIcon, NextStepIcon } from 'components/svgs';
import { Loading } from 'components/Loader';

interface Props {
  onPreviousStep: () => void;
  onForwardStep: () => void;
  isPageUserReservationPreview: any;
  isForwardButtonDisabled: boolean;
}

export default function NavigationButtons({
  onPreviousStep,
  onForwardStep,
  isPageUserReservationPreview,
  isForwardButtonDisabled,
}: Props) {
  return (
    <section
      data-aos="fade-in"
      data-aos-once="true"
      data-aos-duration="1500"
      className="flex w-full flex-row items-center justify-between print:hidden"
    >
      <button
        type="button"
        onClick={onPreviousStep}
        className="inline-flex items-center rounded-full border border-primary bg-gray-50 px-3 py-3 text-center text-base font-medium text-primary transition-all hover:bg-primary hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 sm:px-6 sm:py-4"
      >
        <span>Προηγούμενο</span>
        <TickIcon className="ml-2.5 h-6 w-6" />
      </button>

      <button
        type="button"
        id="button-forward"
        disabled={isForwardButtonDisabled}
        onClick={onForwardStep}
        className="inline-flex items-center rounded-full border border-primary bg-primary px-3 py-2.5 text-center text-lg font-medium text-white transition-all hover:bg-blue-50 hover:text-primary focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 sm:px-6 sm:py-3.5"
      >
        <span>
          {isPageUserReservationPreview.emailVerification
            ? 'Επιβεβαίωση email'
            : 'Επόμενο'}
        </span>
        {!isPageUserReservationPreview.isFetching ? (
          <NextStepIcon className="ml-2.5 h-6 w-6" />
        ) : (
          <div className="flex h-8 w-10 items-center justify-end pe-3 pt-3">
            <Loading size={25} />
          </div>
        )}
      </button>
    </section>
  );
}
