import { InsuranceProvidersType, VisitorInfoFormType } from 'types';
import { useInsuranceProviders } from 'api';
import { useEffect, useMemo } from 'react';
import useStoreServices from 'storeServices';
import { isEmpty } from 'lodash';

interface Props {
  user?: VisitorInfoFormType;
}

export default function UserReservationPreview({ user }: Props) {
  const { availableInsuranceProviders, setAvailableInsuranceProviders } =
    useStoreServices();
  const { data: insuranceProviders, isFetching } = useInsuranceProviders();

  const insuranceProviderName = useMemo(() => {
    const insuranceProvider = availableInsuranceProviders?.find(
      (provider) => provider.id === user?.insurance_provider?.id,
    );
    return insuranceProvider?.name;
  }, [availableInsuranceProviders, user?.insurance_provider]);

  useEffect(() => {
    !isFetching &&
      !isEmpty(insuranceProviders) &&
      isEmpty(availableInsuranceProviders) &&
      setAvailableInsuranceProviders(
        insuranceProviders as InsuranceProvidersType[],
      );
  });

  return (
    <>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1000"
        className="mb-10 rounded-3xl px-4 py-10 text-center xl:px-14 2xl:px-20"
      >
        <h1 className="mx-auto mb-4 max-w-xs text-center text-xl font-bold sm:max-w-md sm:text-2xl md:max-w-lg md:text-3xl lg:max-w-xl">
          Επιβεβαιώστε τις πληροφορίες της κράτησης και πατήστε υποβολή
        </h1>
        <p className="mx-auto mb-6 text-center sm:mb-10">
          Η κράτηση ισχύει για 15 λεπτά.
        </p>
      </div>

      <section data-aos="fade-in" data-aos-once="true" data-aos-duration="1500">
        <h2 className="mb-6 text-center text-2xl font-bold">
          Στοιχεία Κράτησης
        </h2>

        <div className="mb-10 rounded-3xl bg-gray-50 px-4 py-10 text-center lg:text-left xl:px-14 2xl:px-20">
          {/* <!--appointment review--> */}
          <div className="mx-auto grid max-w-md grid-cols-1 justify-items-start gap-5 rounded-lg bg-white p-5 sm:max-w-xl sm:grid-cols-2 sm:justify-items-center md:max-w-full lg:grid-cols-3 lg:justify-items-start xl:gap-10">
            <div className="space-y-0.5 text-left">
              <span className="text-lg font-bold md:text-xl">
                ΟΝΟΜΑΤΕΠΩΝΥΜΟ
              </span>
              <p className="text-lg font-medium">
                {user?.first_name} {user?.last_name}
              </p>
            </div>

            <div className="space-y-0.5 text-left">
              <span className="text-lg font-bold md:text-xl">
                EMAIL ΕΠΙΚΟΙΝΩΝΙΑΣ
              </span>
              <p className="text-lg font-medium">{user?.email}</p>
            </div>

            <div className="space-y-0.5 text-left">
              <span className="text-lg font-bold md:text-xl">
                ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ
              </span>
              <p className="text-lg font-medium">{user?.phone_number}</p>
            </div>

            <div className="space-y-0.5 text-left">
              <span className="text-lg font-bold md:text-xl">ΑΣΦΑΛΕΙΑ</span>
              <p className="text-lg font-medium">{insuranceProviderName}</p>
            </div>

            <div className="space-y-0.5 text-left">
              <span className="text-lg font-bold md:text-xl">ΑΜΚΑ</span>
              <p className="text-lg font-medium">
                {user?.ssrn === '' ? '-' : user?.ssrn}
              </p>
            </div>

            <div
              className={
                user?.notes &&
                `col-span-1 space-y-4 sm:col-span-2 lg:col-span-3`
              }
            >
              <div className="space-y-0.5 text-left">
                <span className="text-lg font-bold md:text-xl">
                  ΛΕΠΤΟΜΕΡΕΙΕΣ ΘΕΜΑΤΟΣ
                </span>
                {!user?.notes && (
                  <p className="text-lg font-medium">Δεν έχουν προστεθεί</p>
                )}
                <p className="text-lg">{user?.notes}</p>
              </div>
            </div>
          </div>
          {user?.escort && (
            <div className="mt-12">
              <div className="mb-10 text-center text-2xl font-bold">
                Στοιχεία Συνοδού/Επισκέπτη
              </div>
              <div className="mx-auto grid max-w-md grid-cols-1 gap-5 rounded-lg bg-white p-5 sm:max-w-xl sm:grid-cols-2 md:max-w-full lg:grid-cols-3 xl:gap-10">
                <div className="space-y-0.5 text-left">
                  <span className="text-lg font-bold md:text-xl">
                    ΟΝΟΜΑ ΣΥΝΟΔΟΥ/ΕΠΙΣΚΕΠΤΗ
                  </span>
                  <p className="text-lg font-medium">
                    {user?.escort_first_name}
                  </p>
                </div>

                <div className="space-y-0.5 text-left">
                  <span className="text-lg font-bold md:text-xl">
                    ΕΠΩΝΥΜΟ ΣΥΝΟΔΟΥ/ΕΠΙΣΚΕΠΤΗ
                  </span>
                  <p className="text-lg font-medium">
                    {user?.escort_last_name}
                  </p>
                </div>

                <div className="space-y-0.5 text-left">
                  <span className="text-lg font-bold md:text-xl">
                    ΤΗΛΕΦΩΝΟ ΕΠΙΚΟΙΝΩΝΙΑΣ ΣΥΝΟΔΟΥ/ΕΠΙΣΚΕΠΤΗ
                  </span>
                  <p className="text-lg font-medium">{user?.escort_phone}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
